/**
 * This code is Intellectual Property of Laxab Digital Solutions Pvt Ltd, which is a registered company in Pune, India.
 * No part of this code should be copied, edited, transmitted in any form without the written permission of the company.
 */
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import fetchData from "../api/api"
import { useForm } from "react-hook-form"



const Stage4 = () =>{
    const state = useSelector(state=>state)
    const dispatch = useDispatch()
    const { handleSubmit, trigger } = useForm();
    const [input,setInput] = useState([])

    const [quiz,setQuiz] = useState([])
    const [weightage,setWeightage] = useState([])
    const [average,setAverage] = useState(0)
    const time = state.userData.details.Date;

    useEffect(()=>{
        dispatch({type:'BACKDROP_OFF'})


        const getWeightage = async() =>{
            dispatch({type:'BACKDROP_ON'})
            const url = 'api/ce/publicT1/select'
            const body = {
                "sid": 1,
                "request": "Public_Select",
                "bu":"GreyInsights",
                "type":"weightage",
                "select":["*"],
                "condition":[{"form":state.userData.selectedForm,"language":"en","status":1}],
                "conditionType":"AND",
                "order":{
                    "orderBy":"id",
                    "order":"DESC"
                },
                "pageNumber":1,
                "pageSize":50
            }
            const response = await fetchData(url,body)
            try{
                if(response.data.response.dbData.length>0){
                    setWeightage(response.data.response.dbData);
                    dispatch({type:'BACKDROP_OFF'});
                }
                else {dispatch({type:'BACKDROP_OFF'});}
            }
            catch (error) {
                setWeightage(error)
                dispatch({type:'BACKDROP_OFF'});
            }
        }

        const apiChecks = async() =>{
            dispatch({type:'BACKDROP_ON'})
            const url = 'api/ce/publicT1/select'
            const body = {
                "sid": 1,
                "request": "Public_Select",
                "bu":"GreyInsights",
                "type":"quiz",
                "select":["*"],
                "condition":[{"form":state.userData.selectedForm,"language":"en","status":1}],
                "conditionType":"AND",
                "order":{
                    "orderBy":"id",
                    "order":"DESC"
                },
                "pageNumber":1,
                "pageSize":100
            }
            const response = await fetchData(url,body)
            try{
                setQuiz(response)
                if(response.data.response.dbData.length>0){
                    setQuiz(response.data.response.dbData);
                    const temp = response.data.response.dbData.map(item => ({
                        code:state.userData.code, 
                        form:state.userData.selectedForm, 
                        userid:state.userData.username, 
                        questionid:item.id, 
                        answer:0,
                        Date:time
                    }))
                    setInput(temp)
                    //dispatch({type:'BACKDROP_OFF'});
                    getWeightage()
                }
                else {dispatch({type:'BACKDROP_OFF'});}

            }
            catch (error) {
                setQuiz(error)
                dispatch({type:'BACKDROP_OFF'});

            }
            
        }
        apiChecks()

    },[dispatch,state.userData.code,state.userData.selectedForm,state.userData.username,time])

    const handleChange = async (e,type) =>{
        //setInput(prev => ({ ...prev, [type]: parseInt(e.target.value) }));
        const temp = input.map(item=>{
            if(item.questionid===type) return{...item,answer:parseInt(e.target.value)}
            return item
        })
        setInput(temp)
        await trigger(e.target.name);
    }
    useEffect(()=>{
        var sum = 0
        for(let i = 0; i<input.length; i++){
            sum = sum + parseInt(input[i].answer)
        }
        setAverage(sum)

        
    },[input])

    const onSubmit = () =>{

        const updateDb = async (result) =>{
            dispatch({type:'BACKDROP_ON'})
            const url = 'api/ce/custom/final'
            const body = {
                "sid": 1,
                "request": "Public_Select",
                "bu":"GreyInsights",
                "assessment":result,
                "results":input,
                "userinfo":state.userData.details
            }
            await fetchData(url,body)
            try{ dispatch({type:'BACKDROP_OFF'}) }
            catch{ dispatch({type:'BACKDROP_OFF'}) }
        }
        const updateRedux = async(data)=>{

            dispatch({
                type:"USER_EDIT",
                forms:state.userData.forms,
                username:state.userData.username,
                code:state.userData.code,
                details:state.userData.details,
                selectedForm:state.userData.selectedForm,
                assessment:data,
                stage:5
            })
            dispatch({type:"CHANGE_BODY",payload:"Stage5"})
        }

        const getCoI = async()=>{
            dispatch({type:'BACKDROP_ON'})
            const url = 'api/ce/custom/getcoi'
            const body = {
                "sid": 1,
                "request": "Public_Select",
                "bu":"GreyInsights",
                "average":average,
                "form":state.userData.selectedForm
            }
            const response = await fetchData(url,body)
            try{
                if(response.status==="success"){
                    dispatch({type:'BACKDROP_OFF'});
                    updateDb({
                        code:state.userData.code,
                        form:state.userData.selectedForm,
                        userid:state.userData.username,
                        average:average,
                        category:response.data[0].description, 
                        comments:response.data[0].comments,
                        Date:time
                    })
                    updateRedux({
                        code:state.userData.code,
                        form:state.userData.selectedForm,
                        userid:state.userData.username,
                        average:average,
                        category:response.data[0].description, 
                        comments:response.data[0].comments,
                        Date:time
                    })

                }
                else {dispatch({type:'BACKDROP_OFF'});}
            }
            catch (error) {
                setQuiz(error)
                dispatch({type:'BACKDROP_OFF'});

            }

        }
        getCoI()

    }

    return <div style={{width:'100%', justifyContent:'center', textAlign:'center'}}>

        <div className="stdbox" style={{maxWidth:'500px',margin:'50px auto 50px', textAlign:'left',padding:'30px 50px 30px'}}>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div style={{padding:'0px 0 20px 5px'}}><h1>Assessment</h1><div>Please take your time to answer the following {state.userData.selectedForm} assessment questions. Select an option that best describes your view for each of these questions.</div></div>
                {
                    quiz?.length>0 &&
                    quiz.map((item,i)=>(
                        <div style={{padding:'0 0 20px 0'}}>

                            <div style={{padding:'0 0 4px 5px'}}>{item.question}</div>
                            <select style={{width:'calc(100% - 0px)'}} onChange={(e)=>handleChange(e,item.id)}
                                name={item.id} 
                            >
                                {
                                    weightage.length>0 && weightage.map((opt,i)=>{
                                        
                                        if(opt.weight===0)
                                            return <option key={i} value={opt.weight} selected>{opt.option}</option>
                                        else
                                            return <option key={i} value={opt.weight}>{opt.option}</option>
                                    })
                                }
                            </select>

                        </div>
                    ))
                }
                <button className="stdButton" style={{width:'100%'}}>Submit</button>
            </form>
        </div>
        
    </div>
}

export default Stage4